<template>
  <el-dialog title="用户详情" :close-on-click-modal="false" :visible.sync="dialogVisible" width="30%" @close="handleClose">
    <el-card>
      <el-row :gutter="20">
        <el-col class="line-1">
          <div class="img">
            <img class="pic" :src="formInline.headUrl ? formInline.headUrl : 'https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/4FE9D265B1EF406FA50B208FE59A6A34.png'" alt="" />
          </div>
        </el-col>
      </el-row>
      <el-form :model="formInline" class="demo-form-inline" label-width="80px">
        <el-form-item label="昵称">
          <el-input v-model="formInline.nickname" placeholder="请输入昵称"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-select v-model="formInline.sex" placeholder="请选择客户性别">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
            <el-option label="未知" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="注册时间">
          <el-date-picker type="date" placeholder="请选择注册日期" v-model="formInline.createTime" style="width: 100%;" disabled></el-date-picker>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="formInline.phone" placeholder="请输入客户手机号" disabled></el-input>
        </el-form-item>
        <el-form-item label="登陆类型">
          <el-input v-model="formInline.type" placeholder="请输入客户登陆类型"></el-input>
        </el-form-item>
        <el-form-item label="FoodSense解读次数:">
          <el-input v-model="formInline.fdInterpretNum" placeholder="请输入FoodSense解读次数"></el-input>
        </el-form-item>
        <el-form-item label="肠道解读次数:">
          <el-input v-model="formInline.gutInterpretNum" placeholder="请输入肠道解读次数"></el-input>
        </el-form-item>
        <el-form-item label="3个月营养管理:">
          <el-input v-model="formInline.healthManageNum" placeholder="请输入3个月营养管理"></el-input>
        </el-form-item>
        <el-form-item label="肠道管理次数:">
          <el-input v-model="formInline.gutManageNum" placeholder="请输入肠道管理次数"></el-input>
        </el-form-item>
      </el-form>
    </el-card>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="toCustomer">转 为 客 户</el-button>
      <el-button type="primary" @click="submitEDit">确定修改</el-button>
    </span>
    <addUsers @closeAdd="closeAdd" :phone="phone" :phoneTuoM="phone" :info="infomation" v-if="showAddUser"></addUsers>
  </el-dialog>
</template>

<script>
import addUsers from '@/views/customerManagement/component/addUsers'
import { postRequest } from '@/api'
export default {
  data() {
    return {
      dialogVisible: true,
      showAddUser: false,
      infomation: '',
      phone: '',
      phoneTuoM: '',
      formInline: {
        nickname: '',
        sex: '',
        createTime: '',
        phone: '',
        type: ''
      },
    }
  },
  props: ['info'],
  components: { addUsers },
  mounted(){
    if (this.info) {
      postRequest('/appUser/getAppUserInfo', {userId: this.info.userId}).then(res=>{
        console.log(res, '获取用户详细信息')
        res.sex = res.sex.toString()
        if (res.lastLoginType == '0') {
          res.type = '未知'
        } else if (res.lastLoginType == '1') {
          res.type = '手机号'
        } else if (res.lastLoginType == '2') {
          res.type = '微信'
        } else if (res.lastLoginType == '3') {
          res.type = '支付宝'
        } else if (res.lastLoginType == '4') {
          res.type = '移动本机号'
        } else if (res.lastLoginType == '6') {
          res.type = '小程序'
        }
        this.formInline = res
      })
    }
  },
  methods: {
    submitEDit(){
      postRequest('/appUser/appUserUpdate', this.formInline).then(res => {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                this.handleClose()
              })
    },
    handleClose() {
      this.$emit('closeEdit')
    },
    toCustomer(){
      this.showAddUser = true
      this.infomation = ''
      this.phoneTuoM = this.formInline.phoneTuoM
      this.phone = this.formInline.phone
    },
    closeAdd(){
      this.showAddUser = false
      this.handleClose()
    }
  },
}
</script>

<style lang="scss" scoped>
.pic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.line-1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-select {
  width: 100%;
}
.el-row {
  margin-bottom: 25px;
}
.main {
  display: flex;
  justify-content: space-between;
  div {
    flex: 1;
  }
}
.el-card {
  margin-top: -20px;
}
</style>
