<template>
  <div class="organ-management-page">
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="form" class="demo-form-inline searchFrom" size="small">
            <el-form-item label="姓名:">
              <el-input v-model="form.name" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="手机号码">
              <el-input v-model="form.phone" :clearable="true" placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" icon="el-icon-search" :disabled="disabledSearch"
                @click="getAllUserList(1)">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-card shadow="always" class="card">
      <div>
        <el-button type="danger" plain icon="el-icon-delete" @click="handleDelete">删除</el-button>
        <el-button type="primary" plain icon="el-icon-circle-plus" @click="addUser">新增</el-button>
      </div>
      <div class="table-block">
        <div class="Selectnum">已选中 <span style="color:#5479FF">{{ multipleSelection.length }}</span> 条信息</div>
        <el-table :data="tableData" ref="multipleTable" @row-click="handleClickTableRow" v-loading="loading"
          :header-cell-style="{ 'text-align': 'center', 'background': '#EEF1FC', 'color': '#656565' }"
          :cell-style="{ 'text-align': 'center' }" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" fixed width="50"></el-table-column>
          <el-table-column prop="date" label="头像">
            <template slot-scope="scope">
              <img
                :src="scope.row.headUrl ? scope.row.headUrl : 'https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/4FE9D265B1EF406FA50B208FE59A6A34.png'"
                alt="">
            </template>
          </el-table-column>
          <el-table-column prop="nickname" label="昵称"> </el-table-column>
          <el-table-column prop="phone" label="手机号"> </el-table-column>
          <el-table-column prop="xingbie" width="100" label="性别"> </el-table-column>
          <el-table-column prop="state" width="100" label="状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status" active-color="#5479FF" inactive-color="#D5D5D5" :active-value="1"
                @change="changeStatus(scope.row)" :inactive-value="-1">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="160">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
              <!-- <el-button v-if="scope.row.status == '1'" @click="changeShow(scope.row, -1)" type="text" size="small">禁用</el-button>
              <el-button v-if="scope.row.status == '-1'" @click="changeShow(scope.row, 1)" type="text" size="small">启用</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 20, 30, 40]"
        :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="total" background>
      </el-pagination>
    </el-card>
    <userManagementEdit :info="info" v-if="showEdit" @closeEdit="closeEdit"></userManagementEdit>
    <addUser v-if="showAddUser" @closeEdit="closeEdit" />
  </div>
</template>

<script>
import userManagementEdit from '@/views/customerManagement/component/userManagementEdit'
import addUser from './component/addUser.vue'
import { postRequest } from '@/api'
export default {
  data() {
    return {
      form: {
        name: '',
        phone:""
      },
      showAddUser: false,
      showEdit: false,
      tableData: [],
      multipleSelection: [],
      total: null,
      pageNo: 1,
      pageSize: 10,
      loading: true,
      info: {},
      disabledSearch: false,
    }
  },
  components: { userManagementEdit, addUser },
  mounted() {
    this.getAllUserList()
  },
  methods: {
    handleClick(row) {
      console.log(row)
      this.info = row
      this.showEdit = true
    },
    // 点击行选中
    handleClickTableRow(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 选中项
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val
    },
    closeEdit() {
      this.showEdit = false
      this.showAddUser = false
      this.getAllUserList(1)
    },
    addUser() {
      this.showAddUser = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.getAllUserList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNo = val
      this.getAllUserList()
    },
    changeStatus(val) {
      console.log(val)
      let data = {
        userId: val.userId,
        status: val.status
      }
      postRequest('/appUser/setStatusAppUserInfo', data).then(res => {
        console.log(res)
        this.$message({
          message: '状态修改成功',
          type: 'success'
        })
        // this.getAllUserList()
      })
    },
    // changeShow(val, index){
    //   let data = {
    //     userId: val.userId,
    //     status: index
    //   }
    //   postRequest('/appUser/setStatusAppUserInfo', data).then(res=>{
    //     console.log(res)
    //     this.$message({
    //       message: '状态修改成功',
    //       type: 'success'
    //     })
    //     this.getAllUserList()
    //   })
    // },
    handleDelete() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: '请选择删除项',
          type: 'warning'
        })
      } else {
        this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          // this.multipleSelection.map(item => {
          //   let data = {
          //     userId: item.userId
          //   }
          postRequest('/appUser/deleteAppUserInfo', this.multipleSelection).then(res => {
            console.log(res, '删除结果')
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getAllUserList()
          })
          // })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    getAllUserList(no) {
      this.disabledSearch = true
      this.loading = true
      let data = {
        pageNo: no ? no : this.pageNo,
        pageSize: this.pageSize,
        userName: this.form.name ? this.form.name : '',
        phone: this.form.phone ? this.form.phone : '',
      }
      postRequest('/appUser/queryAppUserPage', data).then(res => {
        console.log(res, '分页查询用户信息')
        this.total = res.count
        this.disabledSearch = false
        this.tableData = res.data
        res.data.map(item => {
          // if (item.status == '1') {
          //   item.state = '启用'
          // }if (item.status == '-1') {
          //   item.state = '禁用'
          // }
          if (item.sex == '1') {
            item.xingbie = '男'
          } else if (item.sex == '2') {
            item.xingbie = '女'
          } else if (item.sex == '0') {
            item.xingbie = '未知'
          }
        })
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.disabledSearch = false
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.organ-management-page {
  background: transparent;
}

img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.Selectnum {
  padding: 6px 30px;
  border: 1px solid #BFCBF4;
  font-size: 12px;
  color: #666;
  border-radius: 5px;
  margin-bottom: 10px;
}
</style>
